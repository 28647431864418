import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.scss";
import ExploreMoreButton from "./ExploreMoreButton";
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const EELUMemberOf = () => {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  return (
    <Fragment>
      <div className="memberOf">
        {urlPage == 'en' ?
          <div className="divCardRTLLight" style={{ transform: urlPage === 'en' ? 'scaleX(-1)' : 'none', backgroundColor: '#ffffff', border: 'none', marginRight: urlPage === 'en' ? '72%' : 'none' }}>
            <div className="sloganCardBodyLight" style={{ transform: urlPage === 'en' ? 'scaleX(-1)' : 'none', direction: 'ltr', marginLeft: urlPage === 'en' ? '-3%' : 'none' }}>


              <div className={urlPage == "en" ? "typing-slider-en" : "typing-slider-ar"}>
                <p>{t("member of 1")}</p>
                <p>{t("member of 2")}</p>
                <p>{t("member of 1")}</p>

              </div>
            </div>
            <br></br>
            <br></br>
            <Container
            ><Row>
                <Col xs={{ span: 2 }} md={{ span: 3 }} lg={{ span: 3 }} >
                </Col>
                <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} >
                  <LazyLoadImage src={require('../assets/EELU Member of 1.png')} alt="EELU Member of" width="70%" height="auto" className="eelumember" />

                </Col>
                <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} >
                  <LazyLoadImage src={require('../assets/EELU Member of 2.png')} alt="EELU Member of" width="70%" height="auto" className="eelumember" />

                </Col>

              </Row></Container>
          </div>
          : <div className="divCardRTLLight" style={{ backgroundColor: '#ffffff', border: 'none' }}>
            <div className="sloganCardBodyLight" style={{ direction: 'rtl' }}>


              <div class={urlPage == "en" ? "typing-slider-en" : "typing-slider-ar"}>
                <p>{t("member of 1")}</p>
                <p>{t("member of 2")}</p>
                <p>{t("member of 1")}</p>

              </div>
            </div><Container><Row>
              <Col xs={{ span: 2 }} md={{ span: 3 }} lg={{ span: 3 }} >
              </Col>
              <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} >
                <LazyLoadImage src={require('../assets/EELU Member of 1.png')} alt="EELU Member of" width="70%" height="auto" />

              </Col>
              <Col xs={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }} >
                <LazyLoadImage src={require('../assets/EELU Member of 2.png')} alt="EELU Member of" width="70%" height="auto" />

              </Col>

            </Row></Container>
          </div>}
        {urlPage == 'en' ?
          < div className="BodyDivLight">
            <br /><br /><br />
            <br /><br />
            <br /><br />

          </div>
          :
          < div className="BodyDivLight">
            <br /><br /><br />
          </div>
        }
      </div>
    </Fragment >
  );
};

export default EELUMemberOf;
