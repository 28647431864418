import { useState } from "react";
// import { Alert, Button } from "react-bootstrap";
import SSUForm from "./SSUForm";
import { Link } from "react-router-dom";
import axios from 'axios';
import donne from './Imgs/done.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component';



function SSUDone() {



    return (
        <>

            <div id="thanksalert" >
                <SSUForm />
                {/* {showAlert && ( */}
                <div className="overlay" style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    zIndex: '1000'
                    // justifyContent: 'center',
                    // alignItems: 'center',
                }}
                >
                    {/* {SSU_FORM_ATTACH ? */}
                    <div className="alert-wrapper"
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '32px',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: '9999',

                        }}>
                        <div className="alert-card"
                            style={{
                                borderRadius: '5vw',
                                position: 'relative',
                                width: '50vw',
                                padding: '5vw',
                                backgroundColor: 'white',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                                // borderRadius: '20px',
                                // position: 'relative',
                                // width: '400px',
                                // padding: '20px',
                                // backgroundColor: 'white',
                                // boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)'
                            }}>

                            <div className="alert-content" style={{ textAlign: 'center' }}>
                                <LazyLoadImage src={donne} alt=".." />
                                <p style={{ fontFamily: 'sans-serif', textAlign: 'center', fontSize: '32px', color: '#09154E', direction: 'ltr' }}>
                                    <br></br>
                                    شكرا لك! لتواصلك معنا في الجامعة المصرية للتعلم الالكتروني الاهلية
                                    <br></br>
                                    سيتم التواصل معك قريبا                                </p>
                            </div>
                            <div className="alert-header">
                                {/* <h3>Alert Title</h3> */}
                                <Link to='/'
                                    style={{
                                        backgroundColor: ' rgb(17 35 97)',
                                        justifyContent: 'center',
                                        padding: ' 8px 24px',
                                        borderRadius: '8px',
                                        color: ' #f4f5fc',
                                        fontSize: '20px',
                                        border: '0px',
                                        textDecoration: 'none'
                                    }}>
                                    أغلاق
                                </Link>
                            </div>
                        </div>

                    </div>
                    {/* : ' nOT tRUE'} */}
                </div>

                {/* )} */}
            </div >

        </>

        // <div className="container">
        //     <div className='row' >
        //         <div className="alert-box" style={{ float: 'none', margin: '0 auto' }}>
        //             <div className="alert alert-success">
        //                 <div className="alert-icon text-center">
        //                     <i className="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
        //                 </div>
        //                 <div className="alert-message text-center">
        //                     <strong>Success!</strong> Thank you for signup.
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}
export default SSUDone