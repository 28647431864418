import React, { Fragment } from "react";
 
import {useEffect, useState} from 'react';
import "../styles/Main.scss";
import Footer from "../components/Footer";
import ExploreMoreButton from "../components/ExploreMoreButton";

import { useTranslation } from 'react-i18next';
   
const LanguageToggle = () => {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];

  if(urlPage == "ar"){
    i18n.changeLanguage("ar");
 
  }else if(urlPage == "en"){
    i18n.changeLanguage("en");
    document.documentElement.style.setProperty("--selectedLang", 'ltr')
    document.documentElement.style.setProperty("--selectedMirrorSVG", 'scale(-1,1)')
    document.documentElement.style.setProperty("--selectedFloat", 'right')
 
  }else{
    i18n.changeLanguage("ar");
 
  }
   return (<Fragment> 
  </Fragment>
  );
};

export default LanguageToggle;
