
// import "../styles/Main.scss";

import "../styles/componentsScss/Board.scss"
import { useTranslation } from 'react-i18next';
import axios from "axios";

import { BASE_URL } from "./apiConfig";
import { useEffect, useState } from "react";

const DoctorandResearchesCard = (props) => {

    const url = window.location.href.split("/");

    const [t, i18n] = useTranslation();
    const urlPage = url[3];
    const [Board, setBoard] = useState({});



    return (

        <div className="col-lg-6" >

            <br></br>
            <br></br>
            <svg version="1.1" style={{ margin: '-10% 10%' }} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1154 1002">


                <image width="100%" height="100%" href={require('../pages/Imgs/board2.png')}></image>


                {urlPage === 'en' ? (
                    <>
                        <foreignObject x="60" y="240" width="500" height="400">
                            <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: '40px', color: '#25408a', whiteSpace: 'pre-wrap' }}>
                                {props.name_en}
                            </div>
                        </foreignObject>

                        <text className="" x="60" y="300" fill="#25408a" font-size="40">

                        </text>
                        <text className="" x="60" y="450" fill="#25408a" font-size="35"  >

                            {props.position_en}
                        </text>
                        <text className="" x="60" y="400" fill="#25408a" font-size="35"  >

                            {props.jobName}
                        </text>
                        <text className="" x="60" y="850" fill="#25408a" font-size="35"  >

                            {props.educationalCenterEn}
                        </text>
                        <foreignObject x="60" y="430" width="500" height="400">
                            <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: '35px', color: '#25408a', whiteSpace: 'pre-wrap' }}>
                                {props.collegeEn}
                            </div>
                        </foreignObject>

                        <text className="" x="60" y="500" fill="#25408a" font-size="35"  >


                        </text>
                        <text className="" x="60" y="600" fill="#25408a" font-size="35"  >

                            {props.email}
                        </text>

                    </>
                ) : (
                    <>
                        <text className="" x="690" y="300" fill="#25408a" font-size="40">
                            {props.name}
                        </text>

                        <text className="" x="690" y="450" fill="#25408a" font-size="35"  >

                            {props.position}
                        </text>
                        <text className="" x="690" y="400" fill="#25408a" font-size="35"  >

                            {props.jobName}
                        </text>
                        <text className="" x="690" y="850" fill="#25408a" font-size="35"  >

                            {props.educationalCenter}
                        </text>
                        <text className="" x="690" y="500" fill="#25408a" font-size="35"  >

                            {props.college}
                        </text>
                        <text className="" x="690" y="600" fill="#25408a" font-size="35"  >

                            {props.email}
                        </text>
                    </>


                )}



            </svg>

        </div >




    );
};

export default DoctorandResearchesCard;
