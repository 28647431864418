import React, { Fragment } from "react";

import { useEffect, useState } from 'react';
import "../styles/Main.scss";
import Footer from "../components/Footer";
import ExploreMoreButton from "../components/ExploreMoreButton";
import MainPageBody from "../components/MainPageBody";
import { useTranslation } from 'react-i18next';
import HeaderImg from "../components/AboutUsImg.jpg";
import DefaultHeader from './../components/DefaultHeader';


const pathname = "" + window.location.pathname.split('/').slice(-1).join('/');
const categoryTitle = "" + window.location.pathname.split('/').slice(-2, -1).join('/');
//console.log(pathname);

function UrlTitleFunction(type, text) {
  if (type == "GetTitle") {
    const part = text.split('-').join(' ');
    return part
  } else if (type == "GetAlice") {

    const part = text.split(' ').join('-');
    return part

  }
}

const AboutEELU = () => {
  const [t, i18n] = useTranslation();

  return (<Fragment>
    <div className="BodyText">

      <DefaultHeader title={t(UrlTitleFunction("GetTitle", categoryTitle))}
        backgroundImage={HeaderImg} />



      <MainPageBody category={categoryTitle} index={pathname} />
    </div>
  </Fragment>
  );
};

export default AboutEELU;
