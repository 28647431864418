import { useEffect, useState } from "react";
import { BASE_URL } from "./apiConfig";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { Fragment } from "react";

function SearchDisplay(props) {
  const [searchData, setSearchData] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const url = window.location.href.split("/");
  const urlPage = url[3];
  const [allsearch, setAllSearch] = useState([]);
  const param = useParams();
  const word = param.word;
  const [t, i18n] = useTranslation();
  const par = useParams();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [eventsView, setEventsView] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    setEventsView(false); // Close events view when toggling nav
  };

  useEffect(() => {
    axios.post(`${BASE_URL}/search?q=${word}`).then((res) => {
      setAllSearch(res.data.data);
      // console.log(res.data)
      // console.log(searchData)
    });
  }, []);
  return (
    <Fragment>
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <Row>
            <div className="row row-cols-1 row-cols-md-4 g-2">
              {allsearch.map((search) => {
                return (
                  <div className="cols">
                    <div
                      className="card"
                      style={{
                        border: "none",
                        width: "90%",
                        marginRight: "5%",
                        borderRadius: "5px",
                        height: "93%",
                      }}
                    >
                      {search.imgUrl ? (
                        <LazyLoadImage
                          src={`${BASE_URL}/news/${search.imgUrl}`}
                          className="card-img-top"
                          style={{
                            width: "100%",
                            height: "180px",
                          }}
                          alt=".."
                        />
                      ) : (
                        <LazyLoadImage
                          style={{
                            width: "100%",
                            height: "180px",
                            objectFit: "cover",
                            borderRadius: "5px 5px 0 0",
                          }}
                          src={require("../assets/News/كل ما تريد معرفته عن الجامعة المصرية للتعلم الإلكتروني الأهلية وشروط الالتحاق.jpg")}
                          className="card-img-top"
                          alt=".."
                        />
                      )}

                      <div className="card-body">
                        <h4
                          className="card-title"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: "2",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {" "}
                          {urlPage === "en" ? search.titleEn : search.title}
                        </h4>
                        <p
                          className="card-text"
                          style={{
                            textAlign: urlPage === "en" ? "left" : "right",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "2",
                            fontSize: "12px",
                            color: "#404040",
                            // marginTop: '15px',
                          }}
                        >
                          {" "}
                          {urlPage === "en" ? search.bodyEn : search.body}
                        </p>
                        <Link
                          to={`/News/${search.params}`}
                          onClick={() => {
                            toggleNav();
                          }}
                          state={search}
                          style={{ textDecoration: "none" }}
                        >
                          <button
                            className="btn info"
                            style={{
                              border: "1px solid ",
                              padding: " 4px 40px",
                              borderRadius: "6px",
                              color: " white",
                              // fontSize: '20px',
                              backgroundColor: "#142B7B",
                            }}
                          >
                            {t("Read More")}
                          </button>
                        </Link>
                      </div>
                    </div>
                    <br></br>
                  </div>
                );
              })}
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
export default SearchDisplay;
