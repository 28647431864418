import React, { Fragment } from "react";
import "../styles/Main.scss";
import { useTranslation } from 'react-i18next';
import DefaultHeader from "../components/DefaultHeader";
import { Col, Row, Container, } from "react-bootstrap";
import edu from '../pages/Imgs/Edu.png';

import { Link } from "react-router-dom";
function PostGraduate() {
    const [t, i18n] = useTranslation();

    const url = window.location.href.split("/");

    const urlPage = url[3];
    return (
        <Fragment>
            <DefaultHeader title={t('Post Graduate')}
                backgroundImage={edu} />
            <div className="BodyDiv">

                <Container className="MainPageBodyContainer">

                    <div className="">
                        <Row>

                            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div style={{ color: '#09154E', marginTop: '2%' }}>

                                    <p style={{ fontWeight: '600', fontSize: '22px' }}>

                                        <Link to="" style={{ textDecoration: "none", color: 'rgb(9, 21, 78)' }}>
                                            {t("Master's Degree in Education - E-learning Technology Specialization")}


                                        </Link>
                                    </p>

                                    <p style={{ fontWeight: '400', fontSize: '20px' }}>

                                        <Link to="" style={{ textDecoration: "none", color: 'rgb(9, 21, 78)' }}>
                                            {t("يشترط لقيد الطالب في درجة الماجستير في التربية – تخصص تكنولوجيا التعلم الإلكتروني ما يلي:")}


                                        </Link>
                                    </p>

                                    <p style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("أن يكون الطالب حاصلاً على درجة الدبلوم الخاصة في التربية أو ما يعادلها من كليات التربية ومعاهدها من مصر أو خارجها بتقدير جيد على الأقل، على أن تكون مسبوقة بدرجة الليسانس او البكالوريوس من أي جامعة او معهد معترف به وتوافق عليه لجنة القبول بالجامعة")}</p>

                                    <p style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("At the suggestion of the College Council, the University grants a master's degree in education in the specialization of electronic learning technology. The final assessment of the student is calculated on the basis of the average estimates obtained by the student in the courses studied in addition to the assessment obtained on the letter.")}</p>
                                </div>


                            </Col>


                        </Row>
                        <Row>

                            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div style={{ color: '#09154E', marginTop: '2%' }}>

                                    <p style={{ fontWeight: '600', fontSize: '22px' }}>

                                        <Link to="" style={{ textDecoration: "none", color: 'rgb(9, 21, 78)' }}>
                                            {t('Special Diploma in Education')}


                                        </Link>


                                    </p>
                                    <p className="Subheader" style={{ marginRight: '3.5%' }}>{t("The Student's Enrolment in the Special Diploma degree in Education - E-learning Specialization is Required to:")}</p>
                                    <p style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("Obtaining a general diploma in education or a professional diploma in education from a faculty of education or its equivalent from any university or other scientific institute recognized by the university with at least good recognition.")}</p>

                                </div>


                            </Col>


                        </Row>
                        <Row>

                            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div style={{ color: '#09154E', marginTop: '2%' }}>

                                    <p style={{ fontWeight: '600', fontSize: '22px' }}>

                                        <Link to="" style={{ textDecoration: "none", color: 'rgb(9, 21, 78)' }}>
                                            {t('General Diploma in Education')}


                                        </Link>

                                    </p>
                                    <p className="Subheader" style={{ marginRight: '3.5%' }}>{t("The Student's Enrolment in the General Diploma Degree in Education is Required to:")}</p>
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("Obtaining a first degree from an Egyptian university or equivalent from any university or other scientific institute recognized by the university.")}</li>
                                        <li style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("Admission to the degree takes place in the light of the preferential criteria for comparing applicants and their ratings in the initial university degree, the proportion of each academic qualification, their educational and technological expertise, and the additional preferential criteria set by the university to achieve its objectives and serve the educational process and society.")}</li>
                                    </ul>
                                </div>


                            </Col>


                        </Row>
                        <Row>

                            <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} >
                                <div style={{ color: '#09154E', marginTop: '2%' }}>

                                    <p style={{ fontWeight: '600', fontSize: '22px' }}>

                                        <Link to="" style={{ textDecoration: "none", color: 'rgb(9, 21, 78)' }}>
                                            {t('Professional Diploma in Education')}


                                        </Link>

                                    </p>
                                    <p className="Subheader" style={{ marginRight: '3.5%' }}>{t("The Student's Enrolment in Professional Diploma in Education - E-learning Specialization is Required to:")}</p>
                                    <ul style={{ listStyleType: "disc" }}>
                                        <li style={{ fontSize: '20px', marginRight: '3.5%' }}>{t("Bachelor's degree in Arts and Education or Bachelor's degree in Science and Education or Bachelor's degree in Arts and Education for Basic Education or Bachelor's degree in Childhood and Education with at least a 'good' rating, or equivalent from any university or other scientific institute recognized by the University.")}</li>
                                    </ul>
                                </div>


                            </Col>


                        </Row>



                    </div>

                    <br></br>

                    <br></br>




                </Container>
            </div >
        </Fragment >

    );
}
export default PostGraduate