import React, { Fragment, Player, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/componentsScss/Navbar.scss";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "./apiConfig";
import "../styles/componentsScss/Annoncement.scss";
import Annoncement from "./Annoncement";

const HomePage = () => {
  const [t, i18n] = useTranslation();
  const url = window.location.href.split("/");
  const urlPage = url[3];
  // const [annonc, setAnnonc] = useState([])

  // useEffect(() => {
  //   axios.get(`${BASE_URL}/news/studentNews`)
  //     .then((res) => {

  //       setAnnonc(res.data.data)
  //     })

  // })
  //make a useState

  return (
    <Fragment>
      <Annoncement />

      <div style={{ display: "flex" }}></div>
      <video
        className={urlPage === "en" ? "HomepageVideoEn" : "HomepageVideo"}
        autoPlay
        muted
        loop
      >
        <source src={`${BASE_URL}/videos`} type="video/mp4" />
        <track
          src="captions_en.vtt"
          kind="captions"
          srclang="en"
          label="english_captions"
        />
      </video>

      {/* <div style={{ width: "100%" }}>

        <LazyLoadImage src={Homepage} class="card-img-top" alt="..."

          style={{ transform: urlPage === 'en' ? 'scaleX(-1)' : 'none' }}
        />
        {
          urlPage == 'en' ?

            < h2 className="pageheaderen">
              {t('slogan')}
            </h2>

            : < h2 className="pageheaderAr" >
              {t('Egyptian E-Learning University')}
            </h2>}

        {urlPage == 'en' ?

          <p className='pagesubheaderEn' >
            {t('The Egyptian University for E-Learning aims to provide educational and\n\ntraining services at the highest level of quality, provide the labour market \n\nwith elements capable of dealing  with advanced technologies, and work \n\non the continuous development of the educational environment.')}
          </p>
          :

          <p className="pagesubheaderAr"  >
            {t('The Egyptian University for E-Learning aims to provide educational and\n\ntraining services at the highest level of quality, provide the labour market \n\nwith elements capable of dealing  with advanced technologies, and work \n\non the continuous development of the educational environment.')}
          </p>

        }

      </div> */}
    </Fragment>
  );
};

export default HomePage;
