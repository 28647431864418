import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import DefaultHeader from "../components/DefaultHeader";
import { useTranslation } from "react-i18next";
import gradu from "../pages/Imgs/graduation.jpg";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const GraduationParty = () => {
  const [t, i18n] = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    studentNumber: "",
    email: "",
    personalEmail: "",
    phone: "",
    currentCollege: "",
    graduationTerm: "",
    currentCenter: "",
    notes: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    form.method = "POST";

    const fields = {
      orgid: "00D4L000000i6vU",
      retURL: "http://www.eelu.edu.eg/thank-you",
      name: formData.name,
      "00N4L000003swUf": "حفلة التخرج",
      recordType: "0124L000000DNLd",
      "00N4L000003swUg": "Other",
      "00N4L00000A7gOM": "Doki",
      "00N4L00000ASAKj": "1",
      "00N4L00000A7ewg": formData.studentNumber,
      email: formData.email,
      "00N4L00000ASAMa": formData.personalEmail,
      phone: formData.phone,
      "00N4L00000AS0hq": formData.currentCollege,
      "00N4L00000ASI1o": formData.graduationTerm,
      "00N4L00000AS0i0": formData.currentCenter,
      description: formData.notes,
      status: "New",
      type: "Request",
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Fragment>
      <DefaultHeader title={t("Graduation Party")} backgroundImage={gradu} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={12}>
                <br />
                <div style={{ color: "rgb(17, 38, 113)" }}>
                  {/* SVG Path data */}
                  {t("Graduation Party")}
                </div>
              </Col>
            </Row>
          </div>
          <h3 style={{ marginTop: "2%", color: "rgb(17, 38, 113)" }}>
            نموذج تقديم طلب لحفل التخرج
          </h3>
          <h4 className="ssuCardsTitle">
            يتم السداد خلال 24 ساعة .
          </h4>

          <Row>
            <Col
              className="BodyCol"
              xs={{ span: 11, order: 2 }}
              md={{ span: 5, order: 1 }}
              lg={{ span: 5, order: 1 }}
            >
              <form name="caseForm" onSubmit={handleSubmit}>
                <div class="col" id="numberOfCopies" hidden="hidden">
                  <select
                    id="00N4L00000ASAKj"
                    name="00N4L00000ASAKj"
                    title="Number of copies"
                    class="form-control"
                  >
                    <option value="1">1</option>
                  </select>
                </div>
                <br />
                <input type="hidden" name="orgid" value="00D4L000000i6vU" />
                <input
                  type="hidden"
                  name="retURL"
                  value="http://www.eelu.edu.eg/thank-you"
                />

                <label htmlFor="name">اسم الطالب</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  maxLength="80"
                  className="form-control"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />

                <label htmlFor="studentNumber">الرقم الجامعي</label>
                <input
                  id="studentNumber"
                  name="studentNumber"
                  type="text"
                  maxLength="10"
                  className="form-control"
                  placeholder="XXXXXXX (يرجي ادخال الرقم الجامعي بدون -)"
                  pattern="[0-9]{7}"
                  required
                  value={formData.studentNumber}
                  onChange={handleChange}
                />

                <label htmlFor="email">البريد الإلكتروني الجامعي</label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  maxLength="80"
                  className="form-control"
                  placeholder="xxxxxx@student.eelu.edu.eg"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />

                <label htmlFor="personalEmail">البريد الإلكتروني الشخصي</label>
                <input
                  id="personalEmail"
                  name="personalEmail"
                  type="text"
                  maxLength="80"
                  className="form-control"
                  value={formData.personalEmail}
                  onChange={handleChange}
                />

                <label htmlFor="phone">الموبايل</label>
                <input
                  id="phone"
                  name="phone"
                  type="text"
                  maxLength="11"
                  className="form-control"
                  pattern="^[\u0660-\u0669\u06F0-\u06F90-9]{11}"
                  value={formData.phone}
                  onChange={handleChange}
                />

                <label htmlFor="currentCollege">الكلية المقيد بها</label>
                <select
                  id="currentCollege"
                  name="currentCollege"
                  className="form-control"
                  required
                  value={formData.currentCollege}
                  onChange={handleChange}
                >
                  <option value="">أختر الكلية</option>
                  <option value="Faculty of Computers and Information Technology">
                    كلية الحاسبات والمعلومات
                  </option>
                  <option value="Faculty of Commercial Studies and Business Administration">
                    كلية الدراسات التجارية وإدارة الأعمال
                  </option>
                  <option value="الدراسات العليا والدبلومات">
                    الدراسات العليا والدبلومات
                  </option>
                </select>

                <label htmlFor="graduationTerm">دور التخرج</label>
                <select
                  id="graduationTerm"
                  name="graduationTerm"
                  className="form-control"
                  required
                  value={formData.graduationTerm}
                  onChange={handleChange}
                >
                  <option value="">أختر دور التخرج</option>
                  <option value="دور تخرج فبراير لعام 24/25">
                    دور تخرج فبراير لعام 24/25
                  </option>
                  <option value="دور تخرج يوليو لعام  24/25">
                    دور تخرج يوليو لعام 24/25
                  </option>
                </select>

                <label htmlFor="currentCenter">المركز الدراسي المقيد به</label>
                <select
                  id="currentCenter"
                  name="currentCenter"
                  className="form-control"
                  required
                  value={formData.currentCenter}
                  onChange={handleChange}
                >
                  <option value="">أختر المركز</option>
                  <option value="Ain Shams">عين شمس</option>
                  <option value="Alexandria">الإسكندرية</option>
                  <option value="Alfayoum">الفيوم</option>
                  <option value="Alsadat">السادات</option>
                  <option value="Assuit">أسيوط</option>
                  <option value="Aswan">أسوان</option>
                  <option value="Beni Suef">بني سويف</option>
                  <option value="Hurghada">الغردقة</option>
                  <option value="Ismailia">الإسماعيلية</option>
                  <option value="Menoufia">المنوفية</option>
                  <option value="Qena">قنا</option>
                  <option value="Sohag">سوهاج</option>
                  <option value="Tanta">طنطا</option>
                </select>

                <label htmlFor="notes">ملاحظات</label>
                <textarea
                  id="notes"
                  name="notes"
                  className="form-control"
                  value={formData.notes}
                  onChange={handleChange}
                />

                <button
                  type="submit"
                  className="btn btn-info"
                  style={{
                    backgroundColor: " rgb(17 35 97)",
                    justifyContent: "center",
                    padding: " 8px 24px",
                    borderRadius: "8px",
                    color: " #f4f5fc",
                    fontSize: "20px",
                    border: "0px",
                    marginTop: "2%",
                    marginBottom: "3%",
                  }}
                >
                  {t("Submit")}
                </button>
              </form>
            </Col>
            <Col
              className="BodyCol"
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              lg={{ span: 6, order: 1 }}
            >
              <iframe
                className="learningCenterVideo1"
                tilte="EELU"
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/ha-IqzvNbeQ?si=pVfvQYYAwBsZ-TR2"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default GraduationParty;
