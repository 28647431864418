import React, { Fragment } from "react";

import { useEffect, useState } from 'react';

import { Col, Row, Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/Main.scss";
import ExploreMoreButton from "../components/ExploreMoreButton";
import { useTranslation } from 'react-i18next';
import centers from "../pages/Imgs/Centers.png";
import DefaultHeader from "../components/DefaultHeader";
import { Link } from 'react-router-dom';
import Centers from './../components/Centers';


const pathname = "" + window.location.pathname.split('/').slice(-1).join('/');
const categoryTitle = "" + window.location.pathname.split('/').slice(-2, -1).join('/');
//console.log(pathname);
const url = window.location.href.split("/");
const urlPage = url[3];
function UrlTitleFunction(type, text) {
  if (type == "GetTitle") {
    const part = text.split('-').join(' ');
    return part
  } else if (type == "GetAlice") {

    const part = text.split(' ').join('-');
    return part

  }
}

const LearningCenter = () => {
  const [t, i18n] = useTranslation();
  //const bodyImage=t(UrlTitleFunction("GetTitle", categoryTitle)+"."+UrlTitleFunction("GetTitle", pathname)+".image");

  return (<Fragment>
    <DefaultHeader title={t("Centers Title")}
      backgroundImage={centers} />

    <Centers />

  </Fragment>
  );
};

export default LearningCenter;
