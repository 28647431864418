// import "../styles/Main.scss";

import "../styles/componentsScss/Board.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { BASE_URL } from "./apiConfig";
import { useEffect, useState } from "react";

const BoardOfTrusteesCards = (props) => {
  const url = window.location.href.split("/");

  const [t, i18n] = useTranslation();
  const urlPage = url[3];
  const [Board, setBoard] = useState({});

  useEffect(() => {
    axios.get(`${BASE_URL}/boardOfTrustees`).then((res) => {
      setBoard(res.data.data);
    });
    // console.log(Board)
  }, []);

  return (
    <div className="col-lg-6">
      <br></br>
      <br></br>
      <svg
        version="1.1"
        style={{ margin: "-10% 10%" }}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1154 1002"
      >
        <image
          width="100%"
          height="100%"
          href={require("../pages/Imgs/card.png")}
        ></image>

        <image
          x="750"
          y="250"
          width="350"
          height="400"
          href={`${BASE_URL}/boardOfTrustees/${props.imageUrl}`}
        ></image>

        {urlPage === "en" ? (
          <>
            <foreignObject x="50" y="250" width="600" height="400">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                style={{
                  fontSize: "40px",
                  color: "#25408a",
                  whiteSpace: "pre-wrap",
                }}
              >
                {props.name_en}
              </div>
            </foreignObject>

            <foreignObject x="50" y="390" width="600" height="400">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                style={{
                  fontSize: "35px",
                  color: "#25408a",
                  whiteSpace: "pre-wrap",
                }}
              >
                {props.position_en}
              </div>
            </foreignObject>

            <text className="" x="60" y="400" fill="#25408a" font-size="35">
              {props.jobName}
            </text>
            <text className="" x="60" y="850" fill="#25408a" font-size="35">
              {props.educationalCenterEn}
            </text>
            <text className="" x="60" y="500" fill="#25408a" font-size="35">
              {props.collegeEn}
            </text>
            <text className="" x="60" y="600" fill="#25408a" font-size="35">
              {props.email}
            </text>
          </>
        ) : (
          <>
            <text className="" x="690" y="300" fill="#25408a" font-size="40">
              {props.name}
            </text>
            <foreignObject x="200" y="350" width="500" height="400">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                style={{
                  fontSize: "35px",
                  color: "#25408a",
                  whiteSpace: "pre-wrap",
                }}
              >
                {props.position}
              </div>
            </foreignObject>

            <text className="" x="690" y="400" fill="#25408a" font-size="35">
              {props.jobName}
            </text>
            <text className="" x="690" y="850" fill="#25408a" font-size="35">
              {props.educationalCenter}
            </text>
            <text className="" x="690" y="500" fill="#25408a" font-size="35">
              {props.college}
            </text>
            <text className="" x="690" y="600" fill="#25408a" font-size="35">
              {props.email}
            </text>
          </>
        )}
      </svg>
    </div>
  );
};

export default BoardOfTrusteesCards;
